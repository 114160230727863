import React from "react";
import { BsClipboardData } from "react-icons/bs";
import { DiCodeigniter } from "react-icons/di";
import { GiCommercialAirplane } from "react-icons/gi";
import { FaMobileAlt } from "react-icons/fa";
import { BiRocket } from "react-icons/bi";

const homeConfig = {
  greeting: (
    <h1 className="heading">
      Hi! I'm <strong className="main-name"> Rifat Zahir</strong>
    </h1>
  ),
  titles: [
    "A Data Scientist",
    "Data Analysis & Visualization",
    "ETL Processes & SQL Database Administration",
    "Azure DevOps & Cloud Solutions",
    "Inter-agency Coordination & Collaboration",
  ],
  about: {
    start:
      "As a dedicated Data specialist with over eight years of experience in transforming humanitarian data into actionable insights, " +
      "I am passionate about leveraging data to drive impactful decision-making," +
      " and spreading the word of data!",
    exit:
      "I'm fluent at Python, SQL databases, BI tools, and more, " +
      "My expertise spans ETL processes, SQL Database Administration, and Azure DevOps, allowing me to excel in complex, high-stakes environments. with a deep interest in machine learning.",
  },
  workTimeline: [
    {
      id: "work-4",
      title: "Data Scientist",
      company: "UNHCR, the UN Refugee Agency",
      description:
        "Working to build scalable ML solutions, LLM's, and RAG architectures for UNHCR Regional Office MENA. Leveraging advanced data science techniques such as machine learning, AI, and predictive analytics to enhance our understanding of population behavior, needs, and vulnerabilities, significantly improving our capacity to monitor and evaluate the impact of UNHCR's policies and programs.",
      date: "July 2024 - Present",
      icon: <BiRocket />,
      tags: ["ml", "mlops", "python", "azure", "llm", "automl"],
    },
    {
      id: "work-3",
      title: "Information Management Officer",
      company: "UNHCR",
      description:
        "Build AutoML models and make them available to non-technical " +
        "Developed a targeted methodology for emergency cash distribution, enhancing the efficiency of aid delivery to vulnerable populations.",
      date: "Nov 2023 - June 2024",
      icon: <DiCodeigniter />,
      tags: ["ml", "automl", "python", "docker", "redshift", "superset"],
    },
    {
      id: "work-2",
      title: "ODM Officer",
      company: "UNHCR",
      description:
        "Designed reporting tools for inter-agency collaborations and established a unified inter-agency referral platform, " +
        "Trained staff on system data input, delivered technical aid, and crafted internal/external dashboards, strengthening the skills and capacity of the IM team.",
      date: "Sep 2023- Nov 2023",
      icon: <FaMobileAlt />,
      tags: [
        "ml",
        "airflow",
        "python",
        "docker",
        "snowflake",
        "postgres",
        "azure",
      ],
    },
    {
      id: "work-1",
      title: "Associate ODM Officer",
      company: "UNHCR",
      description:
        "Represented the IM team at Senior Management Team (SMT). " +
        "Designed reporting tools for inter-agency collaborations and established a unified inter-agency referral platform.",
      date: "Jan 2022-Oct 2023",
      icon: <GiCommercialAirplane />,
      tags: ["ml", "mssql", "python", "docker", "pbi", "azure"],
    },
    {
      id: "work-0",
      title: "National Professional Officer",
      company: "UNHCR",
      description:
        "Engaged in the development and application of protection and registration procedures, guiding operations through data protection assessments and fostering data-sharing agreements.",
      date: "May 2020- Jan 2022",
      icon: <BsClipboardData />,
      tags: ["python", "mssql", "pbi", "excel"],
    },
  ],
};

export default homeConfig;
